"use client";
import { useEffect, useState } from "react";
import { useNotificationContext } from "@/context/NotificationContext";

export default function Notification() {
  const [shouldDisplay, setShouldDisplay] = useState(false);

  const { message, setMessage } = useNotificationContext();

  useEffect(() => {
    if (!message) {
      setShouldDisplay(false);
      return;
    }

    setShouldDisplay(true);

    const timer = setTimeout(() => {
      setShouldDisplay(false);

      setTimeout(() => {
        setMessage("");
      }, 500);
    }, 2500);

    return () => clearTimeout(timer);
  }, [message, setMessage]);

  return (
    <div className="z-notification fixed flex w-full justify-center">
      <div
        className={`
          fixed top-5 mx-auto max-w-full border-l-4
          border-solid
          border-l-yellow-100
          bg-gray-700 px-4
          text-sm font-semibold text-gray-100
          shadow-md
          shadow-gray-500/15 transition-all
          duration-300 md:max-w-[640px]
          ${shouldDisplay ? "h-fit py-4 opacity-100" : "h-0 py-0 opacity-0"}
        `}
      >
        {message}
      </div>
    </div>
  );
}
