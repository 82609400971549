"use client";
import { QueryClientProvider } from "@tanstack/react-query";
import { httpBatchLink, getFetch } from "@trpc/client";
import { useState } from "react";
import { trpc } from "./trpc";
import queryClient from "./query-client";
import { TRPC_API_URL } from "../env";
import { useSession } from "next-auth/react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

export const TrpcProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { data: session } = useSession();

  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: TRPC_API_URL,
          async headers() {
            const accessToken = session?.user.accessToken;
            return {
              Authorization: `Bearer ${accessToken}`,
            };
          },
          fetch: async (input, init?) => {
            const fetch = getFetch();
            return fetch(input, {
              ...init,
            });
          },
        }),
      ],
    }),
  );
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        {children}
        <ReactQueryDevtools />
      </QueryClientProvider>
    </trpc.Provider>
  );
};
