"use client";
import { type ReactElement, type ReactNode, useState } from "react";
import { createContext, useContext } from "react";

const NotificationContext = createContext<{
  message: string | null;
  setMessage: (message: string | null) => void;
}>({
  message: null,
  setMessage: () => {},
});

export function NotificationProvider({
  children,
}: {
  children: ReactElement | ReactNode;
}) {
  const [message, setMessage] = useState<string | null>(null);

  return (
    <NotificationContext.Provider
      value={{
        message,
        setMessage,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}

export const useNotificationContext = () => useContext(NotificationContext);
