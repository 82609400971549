import { WebAppLocationSchema } from "@setmixer-platform/core/webapp/location";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface AudioPlayerState {
  ref: React.RefObject<HTMLAudioElement> | null;
  setRef: (ref: React.RefObject<HTMLAudioElement> | null) => void;
  isPlaying: boolean;
  setIsPlaying: (isPlaying: boolean) => void;
  isAudioPlayerOpen: boolean;
  setIsAudioPlayerOpen: (isAudioPlayerOpen: boolean) => void;
  recording: {
    mp3Url: string;
    mp3WavJsonUrl: string;
  } | null;
  setRecording: (
    recording: {
      mp3Url: string;
      mp3WavJsonUrl: string;
    } | null,
  ) => void;
  currentTime: number;
  setCurrentTime: (currentTime: number) => void;
  volume: number;
  setVolume: (volume: number) => void;
  recordingName: string;
  setRecordingName: (recordingName: string) => void;
  recordingLocation: WebAppLocationSchema | null;
  setRecordingLocation: (
    recordingLocation: WebAppLocationSchema | null,
  ) => void;
  recordingDate: string;
  setRecordingDate: (recordingDate: string) => void;
  recordingCoverImageUrl: string | null;
  setRecordingCoverImageUrl: (recordingCoverImageUrl: string | null) => void;
  instantPlay: boolean;
  setInstantPlay: (instantPlay: boolean) => void;
  musicEvent: {
    id: string;
    shortId: string;
    name: string;
  } | null;
  setMusicEvent: (
    musicEvent: {
      id: string;
      shortId: string;
      name: string;
    } | null,
  ) => void;
  isFullScreen: boolean;
  setIsFullScreen: (isFullScreen: boolean) => void;
}

export const useAudioPlayerStore = create<AudioPlayerState>()(
  devtools((set) => ({
    ref: null,
    setRef: (ref) => set({ ref }),
    isPlaying: false,
    setIsPlaying: (isPlaying) => set({ isPlaying }),
    isAudioPlayerOpen: false,
    setIsAudioPlayerOpen: (isAudioPlayerOpen) => set({ isAudioPlayerOpen }),
    recording: null,
    setRecording: (recording) => set({ recording }),
    currentTime: 0,
    setCurrentTime: (currentTime) => set({ currentTime }),
    volume: 1,
    setVolume: (volume) => set({ volume }),
    recordingName: "",
    setRecordingName: (recordingName) => set({ recordingName }),
    recordingLocation: null,
    setRecordingLocation: (recordingLocation) => set({ recordingLocation }),
    recordingDate: "",
    setRecordingDate: (recordingDate) => set({ recordingDate }),
    recordingCoverImageUrl: null,
    setRecordingCoverImageUrl: (recordingCoverImageUrl) =>
      set({ recordingCoverImageUrl }),
    instantPlay: false,
    setInstantPlay: (instantPlay) => set({ instantPlay }),
    musicEvent: null,
    setMusicEvent: (musicEvent) => set({ musicEvent }),
    isFullScreen: false,
    setIsFullScreen: (isFullScreen) => set({ isFullScreen }),
  })),
);
