"use client";
import {
  type ReactElement,
  type ReactNode,
  useEffect,
  useState,
  createContext,
  useContext,
} from "react";
import nookies from "nookies";

import { trpcHooks } from "@/lib/trpc";
import { type WebAppArtistSchema } from "@setmixer-platform/core/webapp/artist";
import { type WebAppLocationSchema } from "@setmixer-platform/core/webapp/location";
import { useSession } from "next-auth/react";

const UserProfileContext = createContext<{
  artists: WebAppArtistSchema[];
  currentArtist: WebAppArtistSchema | undefined;
  setCurrentArtist: (artist: WebAppArtistSchema | undefined) => void;
  locations: WebAppLocationSchema[];
  currentLocation: WebAppLocationSchema | undefined;
  setCurrentLocation: (location: WebAppLocationSchema | undefined) => void;
}>({
  artists: [],
  currentArtist: undefined,
  setCurrentArtist: () => {},
  locations: [],
  currentLocation: undefined,
  setCurrentLocation: () => {},
});

export function UserProfileProvider({
  children,
}: {
  children: ReactElement | ReactNode;
}) {
  const { data: session } = useSession();
  const [artists, setArtists] = useState<WebAppArtistSchema[]>([]);
  const [currentArtist, setCurrentArtist] = useState<WebAppArtistSchema>();

  const [locations, setLocations] = useState<WebAppLocationSchema[]>([]);
  const [currentLocation, setCurrentLocation] =
    useState<WebAppLocationSchema>();

  const { data: artistsResult } = trpcHooks.webapp.users.artists.useQuery();
  const { data: locationsResult } = trpcHooks.webapp.users.locations.useQuery();
  const trpcUtils = trpcHooks.useUtils();

  useEffect(() => {
    const fetchProfile = async () => {
      if (!session?.user || (!artistsResult && !locationsResult)) {
        return;
      }

      const artists = artistsResult || [];
      const locations = locationsResult || [];

      setArtists(artists);
      setLocations(locations);

      const artistId = nookies.get(undefined, "artistId").artistId;
      if (artists.length > 0) {
        setCurrentArtist(
          artists.find((artist) => artist.id === artistId) || artists[0],
        );
      } else {
        setCurrentArtist(undefined);
      }

      const locationId = nookies.get(undefined, "locationId").locationId;
      if (locations.length > 0) {
        setCurrentLocation(
          locations.find((location) => location.id === locationId) ||
            locations[0],
        );
      } else {
        setCurrentLocation(undefined);
      }

      const initialStartDate = new Date().toISOString().split("T")[0]; // YYYY-MM-DD format

      if (
        !trpcUtils.webapp.recordings.findFavourites.getInfiniteData({
          orderBy: {
            favouriteId: "DESC",
          },
          limit: 15,
        })
      ) {
        await trpcUtils.webapp.recordings.findFavourites.prefetchInfinite({
          orderBy: {
            favouriteId: "DESC",
          },
          limit: 15,
        });
      }

      if (!trpcUtils.webapp.users.artists.getData()) {
        await trpcUtils.webapp.users.artists.prefetch();
      }
      if (!trpcUtils.webapp.users.locations.getData()) {
        await trpcUtils.webapp.users.locations.prefetch();
      }
      for (const a of artists) {
        if (
          !trpcUtils.webapp.artists.fromShortId.getData({ shortId: a.shortId })
        ) {
          await trpcUtils.webapp.artists.fromShortId.prefetch({
            shortId: a.shortId,
          });
        }

        if (
          !trpcUtils.webapp.recordings.find.getInfiniteData({
            filters: {
              artistShortId: a.shortId,
              startedAtLTE: initialStartDate,
              audioFileType: "AUTOMIX",
            },
            orderBy: {
              startedAt: "DESC",
            },
            type: "set",
            limit: 15,
          })
        ) {
          await trpcUtils.webapp.recordings.find.prefetchInfinite({
            filters: {
              artistShortId: a.shortId,
              startedAtLTE: initialStartDate,
              audioFileType: "AUTOMIX",
            },
            orderBy: {
              startedAt: "DESC",
            },
            type: "set",
            limit: 15,
          });
        }
      }

      for (const l of locations) {
        if (
          !trpcUtils.webapp.locations.fromShortId.getData({
            shortId: l.shortId,
          })
        ) {
          await trpcUtils.webapp.locations.fromShortId.prefetch({
            shortId: l.shortId,
          });
        }

        if (
          !trpcUtils.webapp.recordings.find.getInfiniteData({
            filters: {
              locationShortId: l.shortId,
              startedAtLTE: initialStartDate,
            },
            type: "recording",
            orderBy: {
              startedAt: "DESC",
            },
            limit: 15,
          })
        ) {
          await trpcUtils.webapp.recordings.find.prefetchInfinite({
            filters: {
              locationShortId: l.shortId,
              startedAtLTE: initialStartDate,
            },
            type: "recording",
            orderBy: {
              startedAt: "DESC",
            },
            limit: 15,
          });
        }
      }
    };

    fetchProfile();
  }, [artistsResult, locationsResult]);

  useEffect(() => {
    if (currentArtist && currentArtist.id) {
      nookies.set(undefined, "artistId", currentArtist.id);
    }
  }, [currentArtist, setCurrentArtist]);

  useEffect(() => {
    if (currentLocation && currentLocation.id) {
      nookies.set(undefined, "locationId", currentLocation.id);
    }
  }, [currentLocation, setCurrentLocation]);

  return (
    <UserProfileContext.Provider
      value={{
        artists,
        currentArtist,
        setCurrentArtist,

        locations,
        currentLocation,
        setCurrentLocation,
      }}
    >
      {children}
    </UserProfileContext.Provider>
  );
}

export const useUserProfile = () => useContext(UserProfileContext);
