"use client";
import { useAudioPlayerStore } from "@/store/audioPlayer";
import React, { useEffect, useRef, useState } from "react";

enum AudioPlayerState {
  NOT_PLAYING = 1,
  PLAYING,
}

const AudioPlayerBackground = () => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [playerState, setPlayerState] = useState(AudioPlayerState.NOT_PLAYING);

  const { setRef, isPlaying, setCurrentTime, volume, recording } =
    useAudioPlayerStore();

  useEffect(() => {
    if (!audioRef?.current) {
      return;
    }
    const audioElement = audioRef.current;
    const onLoadStart = (e: Event) => {
      if (isPlaying) {
        setPlayerState(AudioPlayerState.PLAYING);
        audioRef.current?.play();
      } else {
        setPlayerState(AudioPlayerState.NOT_PLAYING);
      }
      audioElement.volume = volume;
    };

    const onDurationChange = () => {
      if (!audioRef?.current) {
        return;
      }

      const audioElement = audioRef.current;
      setCurrentTime(audioElement.currentTime);
    };

    const onVolumeChange = () => {
      if (!audioRef?.current) {
        return;
      }
      audioElement.volume = volume;
    };

    const onTimeUpdate = () => {
      if (!audioRef?.current) {
        return;
      }

      const audioElement = audioRef.current;
      setCurrentTime(audioElement.currentTime);
    };

    const onAudioPlay = (e: Event) => {
      setPlayerState(AudioPlayerState.PLAYING);
    };

    const onAudioPause = (e: Event) => {
      setPlayerState(AudioPlayerState.NOT_PLAYING);
    };

    audioElement.addEventListener("loadstart", onLoadStart);
    audioElement.addEventListener("durationchange", onDurationChange);
    audioElement.addEventListener("timeupdate", onTimeUpdate);
    audioElement.addEventListener("play", onAudioPlay);
    audioElement.addEventListener("pause", onAudioPause);
    audioElement.addEventListener("volumechange", onVolumeChange);

    setRef(audioRef);

    return () => {
      audioElement.removeEventListener("loadstart", onLoadStart);
      audioElement.removeEventListener("durationchange", onDurationChange);
      audioElement.removeEventListener("timeupdate", onTimeUpdate);
      audioElement.removeEventListener("play", onAudioPlay);
      audioElement.removeEventListener("pause", onAudioPause);
      audioElement.removeEventListener("volumechange", onVolumeChange);

      setRef(null);
    };
  }, [setRef, isPlaying, setCurrentTime, volume]);

  useEffect(() => {
    if (!audioRef.current) {
      return;
    }

    if (isPlaying) {
      setPlayerState(AudioPlayerState.PLAYING);
    } else {
      setPlayerState(AudioPlayerState.NOT_PLAYING);
    }
  }, [audioRef, recording, isPlaying]);

  useEffect(() => {
    if (!audioRef.current) {
      return;
    }

    switch (playerState) {
      case AudioPlayerState.PLAYING:
        audioRef.current.play();
        break;
      case AudioPlayerState.NOT_PLAYING:
        audioRef.current.pause();
        break;
    }
  }, [audioRef, playerState]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [audioRef, volume]);

  return (
    <>
      <audio
        ref={audioRef}
        src={recording?.mp3Url}
        preload="auto"
        className="hidden"
      />
    </>
  );
};

export default AudioPlayerBackground;
