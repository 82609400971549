"use client";
import { type ReactElement, type ReactNode, useState } from "react";
import { createContext, useContext } from "react";
import PopupDialog from "@/components/PopupDialog";

const DialogContext = createContext<{
  setDialogVisible: (isVisible: boolean) => void;
  setDialogTitle: (title: string) => void;
  setDialog: (element: ReactElement) => void;
}>({
  setDialogVisible: () => {},
  setDialogTitle: () => {},
  setDialog: () => {},
});

export function DialogProvider({
  children,
}: {
  children: ReactElement | ReactNode;
}) {
  const [isDialogVisible, setDialogVisible] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialog, setDialog] = useState(<></>);

  return (
    <DialogContext.Provider
      value={{
        setDialogVisible,
        setDialogTitle,
        setDialog,
      }}
    >
      <PopupDialog
        isOpen={isDialogVisible && !!dialog}
        setIsOpen={(isOpen: boolean) => {
          setDialogVisible(isOpen);
        }}
        title={dialogTitle || ""}
      >
        {dialog || <></>}
      </PopupDialog>

      {children}
    </DialogContext.Provider>
  );
}

export const useDialogContext = () => useContext(DialogContext);
